import { Fragment, memo, useEffect, useRef, useState } from 'react';

// used a package to control the configuration
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';

// Material UI imports
import WarningIcon from '@mui/icons-material/Warning';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Slider from '@mui/material/Slider';
import { Divider, useTheme } from '@mui/material';

// Components
import StorLoc from './StorLoc.jsx';

import {
  storLocModalId,
  EARNESTMAXVALUESDEPS,
  EARNESTCHECKDEPS,
  LIBERTY_PEN_GUN,
  CONFIGURATIONINPUTTYPES,
  DondeProductsID
} from '../../utils/constants.jsx';
import StorLocBestSellingModels from './StorLocBestSellingModels.jsx';
import SelectMultipleMaterials from './SelectMultipleMaterials.jsx';
import RubberGrommet from './RubberGrommet.jsx';
import LookUpTable from './LookUpTable.jsx';
import { useSnackbar } from 'notistack';

const Configurator = memo((props) => {
  const {
    modelRefreshing,
    setModelRefreshing,
    configurator,
    setConfigurationInputs,
    setMaterialColors,
    cachedConfiguration,
    modelData,
    lookupJSON,
    parameterSchema,
    setParameterSchema,
    StorLocNewSchema,
    IsIframe,
    isFrameComponent
  } = props;

  const modelId = new URLSearchParams(location?.search).get('id') || '';
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  // needToLoadFormData state is update the configuration data and hold the configuration data
  // const [needToLoadFormData, setNeedToLoadFormData] = useState({});
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedDrawer, setSelectedDrawer] = useState('');
  const [tempData, setTempData] = useState({});

  // const needToLoadFormDataRef = useRef(needToLoadFormData);
  // needToLoadFormDataRef.current = needToLoadFormData;

  // code Number is fixed by the onshape api response with it's associates typeName
  // 1826 type is associated with the sliderRange typeName
  // 2550 type is associated with the checkbox typeName
  // 105  type is associated with the dropDown typeName
  // 872 type is associated with the textController typeName

  const sliderRange = CONFIGURATIONINPUTTYPES.sliderRange;
  const checkBox = CONFIGURATIONINPUTTYPES.checkBox;
  const dropDown = CONFIGURATIONINPUTTYPES.dropDown;
  const textController = CONFIGURATIONINPUTTYPES.textController;

  const { control, watch, getValues, setValue } = useForm();

  useEffect(() => {
    const subscription = watch(() => {
      setModelRefreshing(true);
      clearTimeout(timerId?.current);
      timerId.current = null;
      timerId.current = window.setTimeout(myFunc, 1600);
    });
    return () => subscription.unsubscribe();  
  }, [watch]);

  //   const timerId = useRef(null);
  //   const myFunc = () => {
  //     clearTimeout(timerId?.current);
  //     timerId.current = null;
  //     setConfigurationInputs(getValues());
  //   };

  // const debounceTimer = useRef(null);
  // useEffect(() => {
  //   const debouncedMyFunc = () => {
  //     if (debounceTimer.current) {
  //       clearTimeout(debounceTimer.current);
  //     }
  //     debounceTimer.current = setTimeout(() => {
  //       setConfigurationInputs(getValues());
  //       setModelRefreshing(false);
  //     }, 500);
  //   };

  //   const subscription = watch(() => {
  //     setModelRefreshing(true);
  //     debouncedMyFunc();
  //   });

  //   return () => {
  //     clearTimeout(debounceTimer.current);
  //     subscription.unsubscribe();
  //   };
  // }, [watch]);

  const handleNumberChange = (value, field, minvalue) => {
    if (isNaN(value)) {
      return;
    } else if (value >= 0) {
      setValue(field?.name, Number(value));
    } else {
      setValue(field?.name, Number(minvalue));
      enqueueSnackbar('Only positive values are allowed', { variant: 'error' });
      document.getElementById(`number-${field?.name}`).value = minvalue;
    }
  };

  const handleSliderChange = (value, field) => {
    const element = document.getElementById(`number-${field?.name}`);
    element.value = value;
  };

  const timerId = useRef(null);

  const myFunc = () => {
    clearTimeout(timerId?.current);
    timerId.current = null;
    // if (modelData?.hasLookupfields) {
    //   const parameterName = modelData?.featureScriptConf?.configurationParameters[0]?.message?.parameterId;
    //   // const latestConfigurationInputs = {};
    //   const latestConfigurationInputs = { ...watch() };
    //   console.log('file: Configurator.jsx:150 ~ myFunc ~ latestConfigurationInputs:', latestConfigurationInputs);
    //   setConfigurationInputs(latestConfigurationInputs);
    // } else {
    setConfigurationInputs(getValues());
    // }
  };

  const handleButtonclick = async (model, configuration) => {
    setSelectedModel(model);
    let modelMaterialColors = [];
    if (StorLocNewSchema) {
      if (Object.hasOwn(configuration, 'FColor')) {
        modelMaterialColors.push(configuration.FColor);
      }
      if (Object.hasOwn(configuration, 'DColor')) {
        modelMaterialColors.push(configuration.DColor);
      }
    } else {
      if (Object.hasOwn(configuration, 'FColor')) {
        modelMaterialColors.push(configuration.FColor.Color);
      }
      if (Object.hasOwn(configuration, 'DColor')) {
        modelMaterialColors.push(configuration.DColor.Color);
      }
    }
    setMaterialColors(modelMaterialColors);
    setTempData(configuration);
  };
  return (
    <>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Card
          sx={{
            border: `1px solid ${modelId === LIBERTY_PEN_GUN && isFrameComponent ? 'black' : theme.palette.primary.dark}`,
            borderRadius: modelId === LIBERTY_PEN_GUN && isFrameComponent ? '0px' : '10px'
          }}
          className='configurator-wrapper'
        >
          <Grid
            container
            spacing={2}
            sx={{ p: 2 }}
            style={{
              maxHeight: '60vh',
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              '&::WebkitScrollbar': {
                display: 'none'
              }
            }}
          >
            <Grid item xs={12} position="relative">
              {modelRefreshing ? (
                <WarningIcon
                  fontSize="medium"
                  style={{
                    color: 'orange',
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    margin: '2px'
                  }}
                ></WarningIcon>
              ) : (
                <></>
              )}
              <form>
                {modelData?.hasLookupfields === true && (
                  <LookUpTable
                    lookupJSON={lookupJSON}
                    parameterSchema={parameterSchema}
                    setParameterSchema={setParameterSchema}
                    watch={watch}
                    control={control}
                    setValue={setValue}
                    modelData={modelData}
                    cachedConfiguration={cachedConfiguration}
                  />
                )}
                {modelId === '64424b7dca971f39d4e31e53' || modelId === '6446b009c5b63632f3c1cb24' ? (
                  <RubberGrommet {...props} />
                ) : (
                  configurator?.configurationParameters &&
                  configurator?.configurationParameters?.map((v, i) => {
                    const checkParamInLUT = parameterSchema?.find((param) => {
                      return param.name === v.message.parameterId;
                    });
                    // FIND OUT IF SAME PARAMETER NAME IS EXISTS IN LOOKUP TABLE JSON THEN NO NEED OF RENDER THE CONFIGURATION PARAMETER
                    if (checkParamInLUT) {
                      return null;
                    }

                    if (v.message.parameterId === 'List_oOTsso6VTQsLsl' && modelData?.modelId === '663c5ed91969a3312eeb96b5') {
                      return null;
                    }

                    let cachedConfigValue = {};
                    if (cachedConfiguration?.configuration && cachedConfiguration?.configuration?.length > 0) {
                      if (modelData?.hasLookupfields) {
                        const tempCached = cachedConfiguration?.configuration.find((param) => {
                          return param.identifier === modelData?.featureScriptConf?.configurationParameters[0]?.message?.parameterId;
                        });

                        const parsedValue = JSON.parse(tempCached?.value);
                        cachedConfigValue = parsedValue[v.message.parameterId];
                      } else {
                        cachedConfigValue = cachedConfiguration?.configuration.find((param) => {
                          return param.identifier === v.message.parameterId;
                        });
                      }
                    }
                    let defaultVal = '';
                    const label = (
                      // <Grid item xs={3} key={i}>
                      <label style={{ marginBottom: 5 }} className="configParamLabel">
                        {v?.message?.parameterName}{' '}
                        {v?.message?.rangeAndDefault?.message?.units ? `(${v?.message?.rangeAndDefault?.message?.units})` : null}
                      </label>
                      // </Grid>
                    );

                    switch (v.type) {
                      case sliderRange:
                        // maxDefault value shoul be 0 because Cola bottle has 0 as max value so it will affect the configurator value and crash the onshape API
                        const min = v.message.rangeAndDefault?.message?.minValue || 0; // eslint-disable-line no-case-declarations
                        let max = v.message.rangeAndDefault?.message?.maxValue || 0; // eslint-disable-line no-case-declarations
                        if (
                          modelId === '663c5ed91969a3312eeb96b5' &&
                          EARNESTMAXVALUESDEPS[v.message.parameterId] &&
                          watch(EARNESTMAXVALUESDEPS[v.message.parameterId]) != null
                        ) {
                          max = watch(EARNESTMAXVALUESDEPS[v.message.parameterId]);
                          if (watch(v.message.parameterId) > max) {
                            setValue(v.message.parameterId, max);
                          }
                        }

                        const isDegrees = v.message.rangeAndDefault?.message?.units == 'degree'; // eslint-disable-line no-case-declarations
                        let stepValue = eval(((max - min) / 3).toFixed(1)); // eslint-disable-line no-case-declarations
                        if (stepValue > 1) {
                          stepValue = 1;
                        }
                        if (props?.modelData.hasSliderInput) {
                          const paramMatched = props?.modelData?.paramRangeStepInc.find((param) => {
                            return param.parameterId === v.message.parameterId;
                          });
                          if (paramMatched) {
                            stepValue = paramMatched.stepValue;
                          }
                        }

                        let isDisable = false;
                        if (modelId === '663c5ed91969a3312eeb96b5') {
                          // if (cachedConfiguration?.configuration?.length > 0) {
                          //   if (cachedConfigValue == 'Default') {
                          //     isDisable = true;
                          //   }
                          // } else
                          if (
                            v.message.parameterId === 'PartialThread' &&
                            watch(EARNESTCHECKDEPS[v.message.parameterId]) == 'Default'
                          ) {
                            isDisable = true;
                            console.log("file: Configurator.jsx:307 ~ configurator?.configurationParameters?.map ~ isDisable:", v.message.parameterId, isDisable, watch(EARNESTCHECKDEPS[v.message.parameterId]));
                          } else if (EARNESTCHECKDEPS[v.message.parameterId] && watch(EARNESTCHECKDEPS[v.message.parameterId]) == false) {
                            
                            isDisable = true;

                          }
                        }

                        defaultVal = v.message.rangeAndDefault?.message?.defaultValue || min;

                        // Need to remove .includes(modelId) in future when we get complete defalut config
                        //in featureScriptConf for mezco new products
                        if (modelData?.hasLookupfields) {
                          const lookupFields = JSON.parse(modelData?.featureScriptConf?.configurationParameters[0]?.message?.defaultValue);
                          defaultVal = lookupFields[v.message.parameterId];
                        }
                        if (cachedConfiguration?.configuration?.length > 0) {
                          if (modelData?.hasLookupfields) {
                            defaultVal = cachedConfigValue;
                          } else {
                            defaultVal = cachedConfigValue?.value.replace(/[^0-9.]/g, '');
                          }
                        }
                        // eslint-disable-next-line no-case-declarations
                        const marks = [
                          {
                            value: min,
                            label: min.toString() + (isDegrees ? '°' : '')
                          },
                          {
                            value: max,
                            label: max.toString() + (isDegrees ? '°' : '')
                          }
                        ];

                        return (
                          <Fragment key={i}>
                            <Controller
                              key={i}
                              name={v.message.parameterId}
                              control={control}
                              defaultValue={defaultVal}
                              render={({ field }) => (
                                <Grid
                                  container={true}
                                  item
                                  xs={12}
                                  direction="row"
                                  justifyContent="space-between"
                                  sx={{ marginBottom: '15px' }}
                                >
                                  <Grid item xs={12} className="lable-name">
                                    {label}
                                  </Grid>
                                  <Grid container item xs={12} alignItems="center" columnSpacing={2} justifyContent="space-between">
                                    <Grid item xs={9} alignItems="center">
                                      <Slider
                                        size="small"
                                        {...field}
                                        onChange={(_, value) => {
                                          field.onChange(value);
                                          handleSliderChange(value, field);
                                        }}
                                        max={max}
                                        min={min}
                                        step={stepValue}
                                        marks={marks}
                                        disabled={isDisable}
                                        sx={{
                                          '& .MuiSlider-markLabel[data-index="0"]': {
                                            transform: 'translateX(0%)'
                                          },
                                          '& .MuiSlider-markLabel[data-index="1"]': {
                                            transform: 'translateX(-100%)'
                                          }
                                        }}
                                      />
                                    </Grid>
                                    <Grid container item xs={3} alignItems="center">
                                      <Input
                                        id={`number-${v.message.parameterId}`}
                                        size="small"
                                        defaultValue={defaultVal}
                                        disableUnderline={true}
                                        onChange={(e) => handleNumberChange(parseFloat(e?.target?.value), field, min)}
                                        inputProps={{
                                          step: stepValue,
                                          min: min,
                                          max: max,
                                          type: 'number'
                                        }}
                                        disabled={isDisable}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                            />
                          </Fragment>
                        );
                        break; // eslint-disable-line no-unreachable

                      case dropDown:
                        // eslint-disable-next-line no-case-declarations
                        const options = v.message.options.map((option) => {
                          return {
                            value: option.message.option,
                            label: option.message.optionName
                          };
                        });

                        defaultVal = options[0].value;
                        if (cachedConfiguration?.configuration?.length > 0) {
                          if (modelData?.hasLookupfields) {
                            defaultVal = cachedConfigValue;
                          } else {
                            defaultVal = cachedConfigValue?.value;
                          }
                        }

                        return (
                          <Fragment key={i}>
                            {/* {label}
                            <Grid item xs key={i}> */}
                            <Controller
                              name={v.message.parameterId}
                              control={control}
                              defaultValue={options.find((c) => c.value == defaultVal)?.value || options[0].value}
                              render={(props) => (
                                <Grid
                                  container={true}
                                  item
                                  xs={12}
                                  mt={0}
                                  direction="row"
                                  justifyContent="space-between"
                                  sx={{ marginBottom: '15px' }}
                                >
                                  <Grid item xs={12} className="lable-name">
                                    {label}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Select
                                      isClearable={false}
                                      isSearchable={true}
                                      className={DondeProductsID.includes(modelId) ? 'donde-foot-color' : ''}
                                      options={options}
                                      defaultValue={options.find((c) => c.value == defaultVal)}
                                      onChange={(selectedOption) => {
                                        props.field.onChange(selectedOption.value),
                                          v.message.parameterId === 'List_T2xN7LBlScORfo'
                                            ? setSelectedDrawer(Number(selectedOption?.label))
                                            : null;
                                      }}
                                      menuPortalTarget={document.querySelector('body')}
                                      styles={{
                                        menu: (provided) => ({
                                          ...provided,
                                          marginBottom: 0,
                                          marginTop: 0
                                        }),
                                        menuList: (provided) => ({
                                          ...provided,
                                          paddingTop: 0,
                                          paddingBottom: 0
                                        })
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                            />
                            {/* </Grid> */}
                          </Fragment>
                        );
                        break; // eslint-disable-line no-unreachable

                      case textController:
                        if (modelId === storLocModalId) {
                          // StorLoc Component is only for the Stor-Loc Product in the StoreFront
                          if (cachedConfiguration?.configuration?.length > 0) {
                            defaultVal = cachedConfigValue?.value;
                          } else {
                            defaultVal = v?.message?.defaultValue;
                          }
                          return (
                            <StorLoc
                              key={i}
                              configParam={v}
                              Controller={Controller}
                              control={control}
                              useForm={useForm}
                              defaultValue={JSON.parse(defaultVal)}
                              setValue={setValue}
                              selectedDrawer={selectedDrawer}
                              setTempData={setTempData}
                              tempData={tempData}
                              StorLocNewSchema={StorLocNewSchema}
                            />
                          );
                        } else {
                          if (cachedConfiguration?.configuration?.length > 0) {
                            defaultVal = cachedConfigValue?.value;
                          }

                          return (
                            <Fragment key={i}>
                              <Controller
                                name={v.message.parameterId}
                                control={control}
                                defaultValue={defaultVal}
                                render={({ field }) => (
                                  <Grid
                                    container={true}
                                    item
                                    xs={12}
                                    mt={0}
                                    direction="row"
                                    justifyContent="space-between"
                                    sx={{ marginBottom: '15px' }}
                                  >
                                    <Grid item xs={12} className="lable-name">
                                      {label}
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Input fullWidth={true} {...field} placeholder="Text" />
                                    </Grid>
                                  </Grid>
                                )}
                              />
                            </Fragment>
                          );
                        }

                        break; // eslint-disable-line no-unreachable

                      case checkBox:
                        defaultVal = v.message.defaultValue == true;
                        let defaultChecked = v.message.defaultValue == true; // eslint-disable-line no-case-declarations
                        if (modelData?.hasLookupfields && cachedConfiguration?.configuration?.length > 0) {
                          defaultVal = cachedConfigValue;
                          defaultChecked = cachedConfigValue == true ? true : false;
                        } else if (cachedConfiguration?.configuration?.length > 0) {
                          defaultVal = cachedConfigValue?.value;
                          defaultChecked = cachedConfigValue?.value == 'true' ? true : false;
                        }

                        return (
                          <div key={i}>
                            <Grid item xs={12} ml={0} key={i}>
                              <Controller
                                name={v.message.parameterId}
                                control={control}
                                defaultValue={defaultVal}
                                render={({ field }) => (
                                  <>
                                    <Checkbox defaultChecked={defaultChecked} defaultValue={defaultVal} {...field} ml={0} size="small" />
                                    <span className="lable-name">{v.message.parameterName}</span>
                                  </>
                                )}
                              />
                            </Grid>
                          </div>
                        );
                        break; // eslint-disable-line no-unreachable

                      default:
                        break;
                    }
                    return (
                      <Grid index={i} key={i}>
                        <label>{v.message.parameterName}:</label>
                        <p>error, unknown input type</p>
                      </Grid>
                    );
                  })
                )}
              </form>
              {IsIframe && modelId == LIBERTY_PEN_GUN ? (
                ''
              ) : (
                <>
                  <Divider sx={{ my: '15px' }} />
                  <SelectMultipleMaterials {...props} />
                </>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {modelId == storLocModalId && !IsIframe && !isFrameComponent && (
        <Grid item xs={12} md={8}>
          <StorLocBestSellingModels
            setStorLocModel={handleButtonclick}
            selectedModel={selectedModel}
            StorLocNewSchema={StorLocNewSchema}
            modelRefreshing={modelRefreshing}
          />
        </Grid>
      )}
    </>
  );
});

export default Configurator;
